@import './colors';

$primaryFont: 'Inter', 'Arimo', 'Arial', sans-serif;

@mixin textColor($color) {
	color: $color;
}

@mixin border($size, $color) {
	border: $size solid $color;
}

@mixin wh($width, $height) {
	width: $width + 0px;
	height: $height + 0px;
}

@mixin textSize($px, $fw: normal, $color: $navy, $applyColor: true) {
	font-size: $px !important;
	font-weight: $fw !important;
	@if $applyColor {
		color: $color !important;
	}
}

@mixin flexLayout($align, $justify) {
	display: flex;
	align-items: $align;
	justify-content: $justify;
}

@mixin margin($space, $side) {
	@if $side==all {
		margin: $space;
	}
	@if $side==x {
		margin-left: $space;
		margin-right: $space;
	}
	@if $side==y {
		margin-top: $space;
		margin-bottom: $space;
	}
	@if $side==top {
		margin-top: $space;
	}
	@if $side==left {
		margin-left: $space;
	}
	@if $side==bottom {
		margin-bottom: $space;
	}
	@if $side==right {
		margin-right: $space;
	}
}

@mixin padding($space, $side) {
	@if $side==all {
		padding: $space;
	}
	@if $side==x {
		padding-left: $space;
		padding-right: $space;
	}
	@if $side==y {
		padding-top: $space;
		padding-bottom: $space;
	}
	@if $side==top {
		padding-top: $space;
	}
	@if $side==left {
		padding-left: $space;
	}
	@if $side==bottom {
		padding-bottom: $space;
	}
	@if $side==right {
		padding-right: $space;
	}
}
