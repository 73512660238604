/* === SmartPass Color Palette === */
$white: #FFFFFF;
$green: #04CD33;
$jade: #00B476;
$navy: #1F195E;
$dark-slate-gray: #7E879D;
$blue-gray: #7f879d;
$background-white: #FCFDFF;
$card-white: #FFFFFF;
$off-white: #ECF1FF;
$light-gray: #D3D6DE;
$ultra-light-gray: #B3B7C1;
$dark-gray: #555558;
$search-gray: #B3B3B3;
$soft-blue: #6651F1;
$orange: #E48C15;

/* === end ===*/

/* New Palette colors */

// Gray
$gray050: #F5F6F8;
$gray100: #F0F2F5;
$gray150: #EAEDF1;
$gray200: #E2E6EC;
$gray300: #D4D9E2;
$gray400: #B7C1CF;
$gray500: #7083A0;
$gray700: #4E5D74;
$gray900: #101418;

// Navy
$navy100: #ECF1FF;
$navy150: #DFE6FA;
$navy200: #C7D2F0;
$navy500: #1F195E;
$navy700: #1A1550;

// Blue
$blue100: #E7F5FC;
$blue500: #139BE6;
$blue150: #DCF0FC;

//Green
$green100: #E5F7F1;
$green150: #D9F4EB;
$green200: #C6ECDF;
$green500: #00B476;
$green700: #009964;

$red100: #FCE9EF;
$red150: #FBE0E8;
$red200: #f3a5be;
$red500: #E32C66;
$red600: #E53935;
$yellow100: #FDF8E5;
$yellow150: #FCF5D9;
$yellow500: #EBBB00;

$purple500: #6651F1;

$orange500: #E48C15;
$orange150: #FBEEDC;

$redorange150: #FEE2E3;
$lightgreen150: #EAF8DD;
$green150: #EAF8DD;
$greenblue150: #DCF6F1;
$darkblue150: #DCE3EC;
$lightblue150: #DAF3F6;
$purple150: #E8E5FD;
$indigo150: #E0E0FD;
$magenta150: #F3DBFA;
$pink150: #FAE1F1;
