@import 'styles/shared';

.pass-limit-counter {
	&.no-limit {
		color: $gray500;
	}
	&.school-limit {
		color: $purple500;
	}

	&.individual-limit {
		color: $orange500;
	}
}
