@use '@angular/material' as mat;
@import 'styles/shared';

@include mat.core();

$dark-primary-text: rgba(black, 0.87);

$mat-white: (
	100: #ffffff,
	500: #ffffff,
	700: #ffffff,
	A100: #ffffff,
	A200: #ffffff,
	A400: #ffffff,
	A700: #ffffff,
	contrast: (
		100: $dark-primary-text,
		500: $dark-primary-text,
		700: $dark-primary-text,
	),
);

// See here for font-config https://stackoverflow.com/a/43775432
$inter-font: mat.define-typography-config(
	$font-family: $primaryFont,
);

$white-palette: mat.define-palette($mat-white);
$white-theme: mat.define-light-theme($white-palette, $white-palette);
@include mat.all-component-themes($white-theme);
@include mat.core($inter-font);

$mat-dark-green: (
	100: #06ff7a,
	500: #04cd33,
	700: #04cd33,
	A100: #04cd33,
	A200: #04cd33,
	A400: #04cd33,
	A700: #04cd33,
	contrast: (
		100: $dark-primary-text,
		500: $dark-primary-text,
		700: $dark-primary-text,
	),
);

$light-green-palette: (
	100: #06ff7a,
	500: #06ff7a,
	700: #06ff7a,
	A100: #06ff7a,
	A200: #06ff7a,
	A400: #06ff7a,
	A700: #06ff7a,
	contrast: (
		100: $dark-primary-text,
		500: $dark-primary-text,
		700: $dark-primary-text,
	),
);

$loading-theme-primary: mat.define-palette($mat-dark-green);
$loading-theme-accent: mat.define-palette(mat.$light-green-palette);
$loading-theme: mat.define-light-theme($loading-theme-accent, $loading-theme-primary);

$sp-blue-grey: (
	50: #7f879d,
	100: #7f879d,
	200: #7f879d,
	300: #7f879d,
	400: #7f879d,
	500: #7f879d,
	600: #7f879d,
	700: #7f879d,
	800: #7f879d,
	900: #7f879d,
	A100: #7f879d,
	A200: #7f879d,
	A400: #7f879d,
	A700: #7f879d,
	contrast: (
		50: $dark-primary-text,
		100: $dark-primary-text,
		200: $dark-primary-text,
		300: $dark-primary-text,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		A100: $dark-primary-text,
		A200: $dark-primary-text,
		A400: white,
		A700: white,
	),
);

$loading-theme-blue-gray-primary: mat.define-palette(mat.$blue-grey-palette);
$loading-theme-blue-gray-accent: mat.define-palette($sp-blue-grey);
$loading-theme-blue-gray: mat.define-dark-theme($loading-theme-blue-gray-accent, $loading-theme-blue-gray-primary);

.loading-theme {
	&_light-gray,
	&_blue-gray {
		@include mat.all-component-themes($loading-theme-blue-gray);
	}
}

// 🎨 Define the new colors for the slider
$slider-track-color: #d4d9e2;  // Track background
$slider-thumb-fill: #4e5d74;   // Thumb fill & active track

@mixin custom-slider-theme($theme) {
  .mat-slider {
    .mat-slider-track-fill {
      background-color: $slider-thumb-fill !important;  /* Change track fill color */
    }

    .mat-slider-track-background {
      background-color: $slider-track-color !important; /* Change track background color */
    }

    .mat-slider-thumb {
      background-color: $slider-thumb-fill !important;  /* Change thumb color */
    }
  }
}

@include custom-slider-theme($white-theme); // Apply to your existing white theme
