@import 'styles/shared';

// Details modal
// Stacked list of detail sections. See flex or activities detail modals for examples.

.detail-container {
	border: 1px solid $gray300;
	background-color: $white;
	color: $gray500;
	border-radius: 0.75rem;

	.detail-item {
		border-bottom: 1px solid $gray300;
		transition: background-color 200ms;
		padding: 1.25rem;
		cursor: pointer;

		&.disabled {
			cursor: default;
		}

		&:hover {
			background-color: $gray100;
		}
	}
	.detail-item:last-child {
		border-bottom: none;
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
	}
	.detail-item:first-child {
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
	}
	.detail-item-title {
		color: $navy500;
		font-weight: 600;
		flex-grow: 1;
		font-size: 1rem; // tw-text-base
		line-height: 1.21rem;
	}

	&.disabled {
		.detail-item {
			cursor: default;

			&:hover {
				background-color: inherit;
			}
		}
	}
}
